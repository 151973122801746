import { rem } from 'polished'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import TextInput from 'components/Common/Form/Input/TextInput'
import OccupancyItemActions from 'components/Common/OccupancyPicker/OccupancyItemActions'
import Pane from 'components/Common/Pane'
import Counter from 'components/Luxkit/Counter'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Heading from 'components/Luxkit/Typography/Heading'
import { mediaQueryDown } from 'components/utils/breakpoint'
import { getMaxOccupancies } from 'lib/offer/occupancyUtils'

const OccupantsContainer = styled(Pane)`
  background-color: transparent;
  flex: 1;
  padding: ${rem(16)};
  border: 1px solid ${(props) => props.theme.palette.neutral.default.five};
  border-radius: ${(props) => props.theme.borderRadius.S};
  box-shadow: none;
`

const OccupantsTypesContainer = styled.div`
  flex-grow: 1;

  > * + * {
    margin-top: ${rem(16)};
  }
`

const AgeType = styled.div`
  flex-grow: 1;

  > * + * {
    margin-top: ${rem(4)};
  }
`

const OccupantIncrementType = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const OccupantsIncrementer = styled(Counter)`
  display: inline-flex;
`

const ItemWrapper = styled(Pane)`
  box-shadow: none;
  background-color: transparent;
`

const AgeContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${rem(12)} ${rem(24)};
  margin-top: ${rem(16)};

  ${mediaQueryDown.mobile} {
    grid-template-columns: 1fr;
  }
`

const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${rem(8)};
`

interface Props
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange' | 'onReset'> {
  title?: string
  // occupancy objects don't have an ID so we need to provide one
  itemId: number
  occupancy: App.Occupants
  name?: string
  maxChildAge: number
  maxInfantAge?: number
  capacities?: Array<App.PackageRoomCapacity>
  onChange: (itemId: number, nextOccupancy: App.Occupants) => void
  onRemove: (itemId: number) => void
  onReset: (itemId: number) => void
  showRemove?: boolean
  childrenAgeLabel?: string
}

function OccupancyItem(props: Props) {
  const {
    maxChildAge,
    maxInfantAge = -1,
    title,
    itemId,
    occupancy,
    onChange,
    onReset,
    onRemove,
    name,
    capacities,
    showRemove,
    childrenAgeLabel = 'at time of check-in',
    ...rest
  } = props

  const { adults, childrenAge = [] } = occupancy

  const [isDirty, setDirty] = useState<boolean>(false)
  const maxCapacities = useMemo(
    () => getMaxOccupancies(capacities, occupancy),
    [occupancy, capacities],
  )

  const onAdultChange = useCallback(
    (val: number) => {
      setDirty(true)
      onChange(itemId, {
        ...occupancy,
        adults: val,
      })
    },
    [occupancy, onChange, itemId],
  )

  const onChildrenChange = useCallback(
    (val: number) => {
      setDirty(true)
      const nextChildren = [...childrenAge]
      if (val > childrenAge.length) {
        // incrementng
        nextChildren.push(-1)
      } else {
        nextChildren.pop()
      }

      const nextInfants = nextChildren.filter(
        (age) => age !== -1 && age <= maxInfantAge,
      ).length

      onChange(itemId, {
        ...occupancy,
        children: nextChildren.length - nextInfants,
        infants: nextInfants,
        childrenAge: nextChildren,
      })
    },
    [childrenAge, onChange, itemId, occupancy, maxInfantAge],
  )

  const onChildrenAgeChange = useCallback(
    (childId: number, age: number) => {
      setDirty(true)
      const nextChildren = [...childrenAge]
      nextChildren[childId] = age
      const nextGuestCounts = {
        ...occupancy,
        childrenAge: nextChildren,
      }
      onChange(itemId, nextGuestCounts)
    },
    [childrenAge, occupancy, onChange, itemId],
  )

  const onResetClick = useCallback(() => {
    setDirty(false)
    onReset(itemId)
  }, [onReset, itemId])

  const onRemoveClick = useCallback(() => {
    onRemove(itemId)
  }, [onRemove, itemId])

  return (
    <ItemWrapper data-testid={`occupancy-item-${itemId}`} {...rest}>
      {title && (
        <TitleSection>
          <Heading variant="heading6" format="titlecase">
            {title}
          </Heading>
          <OccupancyItemActions
            showReset={isDirty && !!onReset}
            showRemove={showRemove}
            onReset={onResetClick}
            onRemove={onRemoveClick}
          />
        </TitleSection>
      )}
      <OccupantsContainer>
        <OccupantsTypesContainer>
          <OccupantIncrementType>
            <label htmlFor="adults">
              <BodyText variant="medium" weight="bold">
                Adults
              </BodyText>
              <BodyText variant="medium" colour="neutral-two">
                Ages {maxChildAge + 1} and above
              </BodyText>
            </label>
            <OccupantsIncrementer
              data-testid={`occupancy-item-${itemId}-adults`}
              value={adults}
              onChange={onAdultChange}
              max={maxCapacities.adults}
              min={1}
              name={`${name}.adults`}
            />
          </OccupantIncrementType>
          <OccupantIncrementType>
            <label htmlFor="children-count">
              <BodyText variant="medium" weight="bold">
                Children
              </BodyText>
              <BodyText variant="medium" colour="neutral-two">
                Up to {maxChildAge} years old
              </BodyText>
            </label>
            <OccupantsIncrementer
              data-testid={`occupancy-item-${itemId}-children`}
              value={childrenAge.length}
              onChange={onChildrenChange}
              max={maxCapacities.children}
              min={0}
              name={`${name}.children`}
            />
          </OccupantIncrementType>
          {childrenAge.length > 0 && (
            <AgeType>
              <label htmlFor="children-ages">
                <BodyText variant="medium" weight="bold">
                  Children's ages
                </BodyText>
                <BodyText variant="medium" colour="neutral-two">
                  ({childrenAgeLabel})
                </BodyText>
              </label>
              <AgeContainer>
                {childrenAge.map((child, index) => (
                  <TextInput
                    key={index}
                    type="number"
                    min={0}
                    max={maxChildAge}
                    placeholder={`Enter child ${index + 1}'s age`}
                    defaultValue={child === -1 ? undefined : child}
                    onChange={(e) =>
                      onChildrenAgeChange(index, Number(e.currentTarget.value))
                    }
                    name={`${name}.childrenAge[${index}]::number`}
                    required
                  />
                ))}
              </AgeContainer>
            </AgeType>
          )}
        </OccupantsTypesContainer>
        <OccupancyItemActions
          showReset={isDirty && !!onReset}
          showRemove={showRemove}
          onReset={onResetClick}
          onRemove={onRemoveClick}
        />
      </OccupantsContainer>
    </ItemWrapper>
  )
}

export default React.memo(OccupancyItem)
